import { Text } from '@shared/components/text';
import { RatingMethod } from '@shared/generated/graphql';
import { RateMetadata } from '@shared/types/quote';
import { motion } from 'framer-motion';
import { DATRateWidget } from './DATRateWidget';

export const TabiRateWidget = ({ metadata }: { metadata: RateMetadata }) => {
  const identifiers = metadata?.response?.identifiers as string[];
  if (!identifiers?.length) return null;

  return (
    <Text type="custom" className="mt-2 text-[10px] text-gray-500">
      Rate requested with customer identifier{identifiers.length > 1 ? 's' : ''}
      : <strong>{identifiers.join(', ')}</strong>
    </Text>
  );
};

export const GreenscreensConfidenceLevel = ({
  metadata,
}: {
  metadata: RateMetadata;
}) => {
  const confidenceLevel = metadata?.response?.confidenceLevel;

  if (!confidenceLevel) return null;

  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex justify-between">
        <Text type="custom" className="text-[10px] text-gray-500">
          Confidence level:
        </Text>
        <Text type="custom" className="text-[10px] text-gray-500">
          {confidenceLevel}
        </Text>
      </div>
      <div className="h-1 w-full bg-gray-200">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${confidenceLevel}%` }}
          transition={{ duration: 0.7 }}
          className={'bg-success-500 h-1'}
        />
      </div>
    </div>
  );
};

export const EchoConfidenceLevel = ({
  metadata,
}: {
  metadata: RateMetadata;
}) => {
  const confidenceLevel =
    metadata?.response?.rate?.serviceOptions?.[0]?.buyRateConfidence;
  if (!confidenceLevel) return null;

  return (
    <div className="flex w-full flex-col gap-1">
      <Text type="custom" className="text-[11px] text-gray-500">
        Confidence: {confidenceLevel.confidenceScore}
      </Text>
      <Text type="custom" className="text-[11px] text-gray-500">
        ${confidenceLevel.rangeLower} - ${confidenceLevel.rangeUpper}
      </Text>
    </div>
  );
};

export const RateConfidence = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  switch (ratingMethod) {
    case RatingMethod.GREENSCREENS:
      return metadata ? (
        <GreenscreensConfidenceLevel metadata={metadata} />
      ) : null;
    case RatingMethod.ECHO:
      return metadata ? <EchoConfidenceLevel metadata={metadata} /> : null;
    case RatingMethod.DAT:
      return null;
    default:
      return null;
  }
};

export const RateWidget = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  switch (ratingMethod) {
    case RatingMethod.GREENSCREENS:
      return null;
    case RatingMethod.DAT:
      return metadata ? <DATRateWidget metadata={metadata} /> : null;
    case RatingMethod.TABI:
      return metadata ? <TabiRateWidget metadata={metadata} /> : null;
    default:
      return null;
  }
};
